import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Layout from "../components/layout";
import SEO from "../components/seo";
import Markdown from "react-markdown";

const DealersPageStyles = styled.main`
  h1 {
    padding: 3rem 0;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }

  .dealers-header {
    font-weight: 400;
    width: 1000px;
    max-width: 95%;
    margin: 0 auto;
    padding-bottom: 2rem;
    border-bottom: 1px solid #000;
  }

  h2 {
    width: 1000px;
    max-width: 95%;
    margin: 2rem auto;
  }

  .dealers {
    width: 1000px;
    max-width: 95%;
    margin: 0 auto 3rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .dealer {
      line-height: 1.25;
      max-width: 340px;
      margin-right: 20px;

      p {
        margin : 0;
      }
    }
  }

  @media (max-width: 800px) {
    .dealers {
      .dealer {
        margin-right: 0;
        margin-bottom: 20px;
        max-width: 100%;
      }
    }
  }
`

export default function DealersPage({ data, location }) {
  const siteTitle = "Dealers"
  const page = data.file.childMarkdownRemark.frontmatter
  const config = page.configuration

  return (
    <Layout location={location} title={config?.title || siteTitle}>
      <SEO
        title={config?.title || siteTitle}
        keywords={config?.keywords || []}
      />
      <DealersPageStyles>
        <h1>{page.title}</h1>
        <p className="dealers-header">FMT products can be purhased at the following locations:</p>
        {page.countries.map(country => (
          <div className="country-section" key={country.name}>
            <h2>{country.name}</h2>
            <div className="dealers">
              {country.dealers.map((dealer, index) => (
                <div className="dealer" key={index}>
                  <Markdown children={dealer.address} />
                  {dealer.phone1 && <p>{dealer.phone1}</p>}
                  {dealer.phone2 && <p>{dealer.phone2}</p>}
                  {dealer.phone3 && <p>{dealer.phone3}</p>}
                  {dealer.fax && <p>{dealer.fax}</p>}
                  {dealer.email && <p>{dealer.email}</p>}
                  {dealer.website && <p>{dealer.website}</p>}
                </div>
              ))}
            </div>
          </div>
        ))}
      </DealersPageStyles>
    </Layout>
  );
}

export const query = graphql`
query DealersPageQuery {
  file(sourceInstanceName: { eq: "pages" }, name: { eq: "dealers" }) {
    name
    childMarkdownRemark {
      id
      frontmatter {
        templateKey
        configuration {
          title
          keywords
        }
        title
        countries {
					name
          dealers {
						address
            phone1
            phone2
            phone3
            fax
            email
            website
          }
        }
      }
    }
  }
}
`
